import {
    SimpleForm,
    TextInput,
    SaveButton,
    useRecordContext, NumberInput, useDataProvider, useSaveContext, 
    Toolbar,SaveContextProvider
} from "react-admin";
import React, {useState, Fragment} from "react";
import BoltIcon from "@mui/icons-material/Bolt";
import {ethers} from "ethers";
import {Card, CardContent} from '@mui/material';

const ContractFunctionToolbar = () => {
    const record = useRecordContext();
    const {save, saving} = useSaveContext();
    return (
        <Toolbar>
            <SaveButton
                label="Execute"
                alwaysEnable={(record?.inputs?.length ?? 0) === 0}
                icon={<BoltIcon />}
                saving={saving}
                onSubmit={save}
                type="button"
            />
        </Toolbar>
    );
}

const ContractFunctionInput = (props: any) => {
    const input = props.input;

    if (input.type === "address" || input.type === "string") {
        return (
            <TextInput label={input.name} source={props.source} helperText={input.type} fullWidth required />
        )
    }

    if (input.type === "uint256") {
        return (
            <NumberInput label={input.name} source={props.source} helperText={input.type} fullWidth required />
        )
    }

    return <></>
}

interface IExecutionResult {
    success: boolean;
    message?: string;
}

export const ContractFunctionExecution = ({recordId}: {recordId: string | undefined}) => {
    const [saving, setSaving] = useState(false);
    const [result, setResult] = useState<IExecutionResult | undefined>(undefined);
    const record = useRecordContext();

    const dataProvider = useDataProvider();

    const handleExecute = async (values: any) => {
        setResult(undefined);
        setSaving(true);
        const getOneResult = await dataProvider.getOne('dapp_contracts', {id: recordId});

        const provider = new ethers.providers.Web3Provider((window as any).ethereum)
        await provider.send('eth_requestAccounts',[]) //this prompts the user to connect metamask

        const contract = new ethers.Contract(getOneResult.data.contractAddress, getOneResult.data.abi, provider.getSigner());

        const fn = contract[values.name];

        if (typeof fn === 'function') {
            const params = Object.values(values.inputValues ? values.inputValues : []);

            try {
                const functionResult = await fn.apply(null, params);

                if (values.stateMutability === 'view' || values.stateMutability === 'pure') {
                    let message;
                    if (typeof functionResult !== 'object') {
                        message = functionResult;
                    } else {
                        if (values.outputs.length > 0 && values.outputs[0].type === 'uint256') {
                            //functionResult will be a BigNumber in this case
                            message = functionResult.toString();
                        }
                        else {
                            message = JSON.stringify(functionResult);
                        }
                    }
                    setResult({success: true, message: message});
                } else {
                    await functionResult.wait();
                    setResult({success: true, message: 'Success'});
                }
            } catch(e) {
                setResult({success: false, message: JSON.stringify(e)});
            }
        }
        
        setSaving(false);
    };

    const mutationMode = 'pessimistic';
    
    return (
        <Fragment>
            <SaveContextProvider value = {{save: handleExecute, saving, mutationMode}}>
                <SimpleForm toolbar={<ContractFunctionToolbar/>}>
                    {record?.inputs ? record.inputs.map((input: any) => <ContractFunctionInput key={input.name} input={input} source={"inputValues[" + input.name + "]"} />) : "No inputs"}
                </SimpleForm>
            </SaveContextProvider>
            <ResultDisplay result={result}/>
        </Fragment>
    )
}

const ResultDisplay = (props: {result: IExecutionResult | undefined}) => {
    if (props.result) {
        return (
            <Card>
                <CardContent style={{backgroundColor: props.result.success ? "#33FF80" : "#FF8989"}}>
                    {props.result.message}
                </CardContent>
            </Card>
        )
    }

    return (
        <></>
    )
}
