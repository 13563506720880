import Card from "@mui/material/Card";
import {
    Title,
    SimpleForm,
    SaveContextProvider,
    required,
    SaveButton,
    TopToolbar,
    FileInput,
    FileField,
    Button
} from "react-admin";
import {CardContent, CardActions} from "@mui/material";
import { useDapp } from "./dapp-context";
import React, {useEffect, useMemo, useState, useCallback} from "react";
import FileUpload from '@mui/icons-material/FileUpload';
import FileDownload from '@mui/icons-material/FileDownload';
import {DappAdminApiService} from "./services/DappAdminApiService";
import { useDefineAppLocation } from "@react-admin/ra-navigation";
import {convertFileToBase64} from "./utils/imageUtils";
import Select from "react-select";
import fileDownload from 'js-file-download';
import {Box} from "@mui/material";
import {OnChangeValue} from "react-select/dist/declarations/src/types";

const UploadTokensToolbar = (props: any) => {
    return (
        <SaveButton
            {...props}
            label="Upload Tokens"
            disabled={props.pristine}
            icon={<FileUpload />}
        />
    );
}

interface ContractSelectorOption {
    value: string,
    label: string
}
export const UploadTokens = () => {
    const dappApiService = new DappAdminApiService();
    const dapp = useDapp();
    const [saving, setSaving] = useState(false);

    useDefineAppLocation('upload_tokens');

    const defaultContractOptions: ContractSelectorOption[] = [];
    const defaultSelectedContract: string = "";
    
    const [contracts, setContracts] = useState(defaultContractOptions);
    const [selectedContract, setSelectedContract] = useState(defaultSelectedContract);

    useEffect(() => {
        async function fetchContracts() {
            const fetchedContracts = await dappApiService.getDappContracts(dapp.dappID);
            const nftContracts = fetchedContracts.filter(function(c: any) { return c.state.ERC1155 || c.state.ERC721 });

            let options: ContractSelectorOption[] = [];
            nftContracts.forEach((c: {contractName: string}) => {
                const option = { value: c.contractName, label: c.contractName };
                options.push(option);
            });
            
            setContracts(options);
        }
        fetchContracts();

    }, [setContracts]);

    const handleContractSelected = (evt: OnChangeValue<ContractSelectorOption, false>) => {
        if (evt?.value) {
            setSelectedContract(evt.value);
        }
    };

    const handleSave = useCallback( async (values) => {
        setSaving(true);

        const fileData = await  convertFileToBase64(values.file.rawFile);
         await dappApiService.uploadTokens(dapp.dappID, {file: fileData});
        setSaving(false);
        
    },[dapp.dappID]);
    
    const handleDownloadClick = useCallback( async (values) => {
        if (!selectedContract) {
            return;
        }

        const result = await dappApiService.getTemplateFile(dapp.dappID, selectedContract);

        if (result) {
            fileDownload(result.data, 'template.csv');
        }
        
    },[dapp.dappID, selectedContract]);
    
    // needed by the saveContext that I don't understand
    const onFailure = () => {
        
    };
    
    //don't really understand this???
    const saveContext = useMemo(() => ({
        setOnFailure: onFailure,
        save: handleSave,
        saving
    }), [saving, handleSave]);


    const record = {
        file: ""
    };
    
    return (
        <>
            <Box sx={{
                height: 300,
            }}>
                <Card variant="outlined" >
                    <CardContent>
                        <Select options={contracts} onChange={handleContractSelected} />
                    </CardContent>
                    <CardActions>
                        <Button
                            disabled={!selectedContract}
                            color="secondary"
                            label="Download Template"
                            onClick={handleDownloadClick}
                            children={<FileDownload />}
                        />
                    </CardActions>
                </Card>
            </Box>
            <Card>
                <Title title="Upload Tokens"/>
                <CardContent>
                    <SaveContextProvider value={saveContext} >
                        <SimpleForm record={record} 
                                    onSubmit={handleSave} 
                                    toolbar={<UploadTokensToolbar />}
                        >
                            <FileInput source="file" label="File" accept="text/csv" validate={required()}>
                                <FileField source="src" title="title" />
                            </FileInput>
                        </SimpleForm>
                    </SaveContextProvider>
                </CardContent>
            </Card>
        </>
    );
}