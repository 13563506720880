import {
    BooleanInput,
    DateInput, DateTimeInput, ImageField, ImageInput,
    maxLength,
    maxValue,
    minLength,
    minValue,
    NumberInput,
    required, SelectInput,
    TextInput
} from "react-admin";
import React from "react";

export const MetadataItem = (props: any) => {
    const template = props.template;
    
    if (template.type === "string") {
        let validation = []
        if (template.required) {
            validation.push(required());
        }
        if (template.minLength || template.minLength === 0) {
            validation.push(minLength(template.minLength));
        }
        if (template.maxLength || template.maxLength === 0) {
            validation.push(maxLength(template.maxLength));
        }

        return (
            <TextInput label={template.label} helperText={template.helpText} source={props.source} validate={validation} />
        )
    }

    if (template.type === "number") {
        let validation = []
        if (template.required) {
            validation.push(required());
        }
        if (template.min || template.min === 0) {
            validation.push(minValue(template.min));
        }
        if (template.max || template.max === 0) {
            validation.push(maxValue(template.max));
        }

        return (
            <NumberInput min={template.min} max={template.max} label={template.label} helperText={template.helpText} source={props.source} validate={validation} />
        )
    }

    if (template.type === "boolean") {
        return (
            <BooleanInput label={template.label} helperText={template.helpText} source={props.source} validate={template.required ? required() : undefined} />
        )
    }

    if (template.type === "date") {
        return (
            <DateInput label={template.label} helperText={template.helpText} source={props.source} validate={template.required ? required() : undefined}/>
        )
    }

    if (template.type === "datetime") {
        return (
            <DateTimeInput label={template.label} helperText={template.helpText} source={props.source} validate={template.required ? required() : undefined}/>
        )
    }

    if (template.type === "select") {
        return (
            <SelectInput choices={template.choices} helperText={template.helpText} label={template.label} source={props.source} validate={template.required ? required() : undefined}/>
        )
    }

    if (template.type === "image") {
        return (
            <ImageInput label={template.label} helperText={template.helpText} source={props.source} validate={template.required ? required() : undefined}>
                <ImageField source="src" title="title" />
            </ImageInput>
        )
    }
    
    return <></>
}
