import { MultiLevelMenu, MenuItemCategory, MenuItemCategoryResources } from "@react-admin/ra-navigation";
import HardwareIcon from '@mui/icons-material/Hardware';
import UploadIcon from '@mui/icons-material/Upload';

export const CustomMenu = () => {
    return (
        <MultiLevelMenu variant="categories">
            <MenuItemCategoryResources hasDashboard={true} />
            <MenuItemCategory name="dapp.create_nft" to="/create_nft" label="Create NFT" icon={<HardwareIcon />}/>
            <MenuItemCategory name="dapp.upload_tokens" to="/upload_tokens" label="Upload Tokens" icon={<UploadIcon />}/>
        </MultiLevelMenu>
    );
}

