import {
    List,
    TopToolbar,
    Datagrid,
    TextField,
    NumberField,
    ChipField,
    EditButton,
    ExportButton,
    ShowButton, useRecordContext
} from "react-admin";
import { FilterList, FilterListItem } from 'react-admin';
import {Card as MuiCard, CardContent, Chip} from '@mui/material';
import {withStyles} from '@mui/styles';
import { useDapp } from '../dapp-context';

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const ContractListTitle = (props: any) => {
    return <span>{`Contracts for ${props.dapp ? props.dapp.dappID : 'DApp'}`}</span>;
};

const ContractListActions = (props: any) => {
    return(
        <TopToolbar>
            {/*<CustomBreadcrumb {...props} variant="actions" />*/}
            <ExportButton
                disabled={props.total === 0}
                resource={props.resource}
            />
        </TopToolbar>
    );
};

const FilterListSets = () => {
    return (
        <FilterList label="set" icon={null}>
            <FilterListItem label="Current"  value={{ contractSet: "current" }}/>
            <FilterListItem label="Staged" value={{ contractSet: "staged" }}/>
        </FilterList>
    );
};

const FilterListTypes = () => {
    return(
        <FilterList label="type" icon={null}>
            <FilterListItem label="Managed"  value={{ contractType: "Managed" }}/>
            <FilterListItem label="External" value={{ contractType: "External" }}/>
        </FilterList>
    );
};

const FilterListTags = () => {
    const dapp = useDapp();

    let allTags = [];
    for (let c of dapp.contracts) {
        for (let t of c.contractTags) {
            if (allTags.indexOf(t) < 0) {
                allTags.push(t);
            }
        }
    }
    allTags.sort();

    return(
        <FilterList label="tag" icon={null}>
            {allTags.map((t) => <FilterListItem label={t} key={t} value={{ contractTags: t }}/>)}
        </FilterList>
    );
};

const ContractListAside = () => {
    return(
        <Card>
            <CardContent>
                <FilterListSets/>
                <FilterListTypes/>
                <FilterListTags/>
            </CardContent>
        </Card>
    );
};

const ContractTagsField = ({...props}) => {
    const record = useRecordContext();

    const filterTags = ['ERC20', 'ERC721', 'ERC1155'];

    return (
        <>
            {record.contractTags.filter((c: string) => filterTags.indexOf(c) >= 0).map((tag: any) => (
                <Chip label={tag} key={tag} size="small" />
            ))}
        </>
    );
};

export const ContractList = (props: any) => {
    const dapp = useDapp();

    // <NumberField source="contractAddress" label="Contract Address" />

    return (
        <List {...props} title={<ContractListTitle dapp={dapp}/>} actions={<ContractListActions/>} aside={<ContractListAside/>} >
            <Datagrid isRowSelectable={record => false}>
                <TextField source="contractSet"  label="Set" />
                <TextField source="contractType" label="Type" />
                <TextField source="contractName" label="Name" />
                <ContractTagsField label="Tag" />
                <NumberField source="contractSequence" label="Sequence" />
                <NumberField source="blockAt" label="Block At" />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
}