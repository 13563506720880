import React from "react";
import {Edit, SimpleForm, TextInput, NumberInput, TopToolbar, 
    required, Confirm, useUpdate, useNotify, ArrayInput, SimpleFormIterator} from "react-admin";
import SyncIcon from '@mui/icons-material/Sync';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const DappEditTitle = ({ record }: any) => {
    return <span>{record ? `"${record.dappID}"` : ''}</span>;
};

const DappEditActions = ({ basePath, data }: {basePath?: any, data?: { id:string; processingState: any }}) => {
    const dapp = data;
    const [open, setOpen] = React.useState(false);
    const [update] = useUpdate();
    const notify = useNotify();

    const handleReprocess = () => {
        setOpen(true);
    };

    const handleConfirm = () => {
        setOpen(false);
        if (!dapp) {
            return;
        }
        
        delete dapp.processingState;
        update('dapps', {id: dapp.id, data: dapp, previousData: dapp});
        notify("Reindexing triggered");
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <TopToolbar>
            <Tooltip title="Clears the processing state to trigger a reindex" arrow>
                <Button color="primary" startIcon={<SyncIcon/>} onClick={handleReprocess} >Reprocess</Button>
            </Tooltip>
            <Confirm title="" content="Are you sure you want to clear the processing state to trigger a reindex?"
                     isOpen={open} onClose={handleClose} onConfirm={handleConfirm} confirm="Yes" cancel="No" />
        </TopToolbar>        
    )  
};

const validateDisplayName = [required()]

export const DappEdit = (props: any) => {
    return (
        <Edit {...props} title={<DappEditTitle/>} actions={<DappEditActions/>}>
            <SimpleForm warnWhenUnsavedChanges={true}>
                <TextInput disabled source="id" label="Id"/>
                <TextInput disabled source="dappID" label="Dapp Id"/>
                <NumberInput disabled source="networkID" label="Network Id"/>
                <NumberInput disabled source="chainID" label="Chain Id"/>
                <TextInput source="displayName" label="Display Name" validate={validateDisplayName}/>
                <ArrayInput source="contracts">
                    <SimpleFormIterator disableAdd disableRemove disableReordering>
                        <TextInput disabled source="contractID" label="Contract Id" fullWidth/>
                        <TextInput disabled source="contractName" label="Contract Name" fullWidth/>
                        <TextInput disabled source="contractAddress" label="Contract Address" fullWidth/>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    )
};