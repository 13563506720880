import {
    ArrayField,
    Datagrid,
    NumberField,
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    FunctionField, useRecordContext
} from "react-admin";
import React from "react";
import {ContractFunctionExecution} from "./ContractFunctionExecution";
import { useParams } from "react-router-dom";

const ContractShowTitle = () => {
    const record = useRecordContext();
    return <span>{record ? `Contract Details for ${record.contractName}` : ''}</span>;
};


export const ContractShow = () => {
    const { id } = useParams();

    return (
        <Show title={<ContractShowTitle/>}>
            <TabbedShowLayout>
                <Tab label="summary">
                    <TextField source="id" />
                    <TextField source="dappID" />
                    <TextField source="contractID" />
                    <TextField source="contractName" />
                    <TextField source="contractType" />
                    <TextField source="contractAddress" />
                    <NumberField source="blockAt" />
                    <TextField source="contractTags" />
                </Tab>
                <Tab label="Contract Functions">
                    <ArrayField source="contractFunctions" label="">
                        <Datagrid expand={<ContractFunctionExecution recordId={id} />} style={{tableLayout: 'fixed'}} bulkActionButtons={false} expandSingle={true}>
                            <FunctionField label="name" render={(record: any) => `${record?.name} ${record?.outputs.length > 0 ? " => " + record.outputs[0].type : ""}`} />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );    
}
    
