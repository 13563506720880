import React from "react";
import {
    Edit, SimpleForm, TextInput} from "react-admin";
import { JsonInput } from "react-admin-json-view";

const OffChainTokenEditTitle = ({ record }: any) => {
    return <span>{record ? `Off Chain Token Details for ${record.id}` : ''}</span>;
};

export const OffChainTokenEdit = (props: any) => {
    return (
        <Edit {...props} title={<OffChainTokenEditTitle/>} >
            <SimpleForm warnWhenUnsavedChanges={true}>
                <TextInput disabled source="id" />
                <TextInput disabled source="dappID" label="Dapp Id" />
                <TextInput disabled source="contractID" label="Contract Id" />
                <TextInput source="gameAssetIdentifier" label="Game Asset Identifier" />
                <TextInput source="gameAccountIdentifier" label="Game Account Identifier" />
                <TextInput source="claimStatus" label="Claim Status" />
                <TextInput source="tokenID" label="Token Id" />
                <JsonInput
                    source="metadata"
                    jsonString={false} // Set to true if the value is a string, default: false
                    reactJsonOptions={{
                        // Props passed to react-json-view
                        name: null,
                        collapsed: false
                    }}
                />
            </SimpleForm>
        </Edit>
    )
};
