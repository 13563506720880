import { List, TopToolbar, Datagrid, TextField, NumberField, EditButton, ExportButton } from "react-admin";
import { FilterList, FilterListItem } from 'react-admin';
import { FilterLiveSearch } from 'react-admin';
import { Card as MuiCard, CardContent } from '@mui/material';
import {withStyles} from '@mui/styles';
import { useDapp } from '../dapp-context';

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const OffChainTokenListTitle = (props: any) => {
    return <span>{`Off Chain Tokens for ${props.dapp ? props.dapp.dappID : 'DApp'}`}</span>;
};

const OffChainTokenListActions = (props: any) => (
    <TopToolbar>
        <ExportButton
            disabled={props.total === 0}
            resource={props.resource}
        />
    </TopToolbar>
);

const FilterListContracts = (props: any) => {
    return(
        <FilterList label="contract" icon={null}>
            {props.dapp.contracts.map((c: any) => <FilterListItem label={c.contractName} key={c.contractName} value={{ contractName: c.contractName }}/>)}
        </FilterList>
    );
};

const FilterListClaimStatus = (props: any) => {
    let filterTags = ['created', 'pending', 'claimed'];

    return(
        <FilterList label="Claim Status" icon={null}>
            {filterTags.map((t) => <FilterListItem label={t} key={t} value={{ claimStatus: t }}/>)}
        </FilterList>
    );
};

const OffChainTokenListAside = (props: any) => {
    return(
        <Card>
            <CardContent>
                <FilterLiveSearch source="contractName"/>
                <FilterListContracts dapp={props.dapp}/>
                <FilterListClaimStatus dapp={props.dapp}/>
            </CardContent>
        </Card>
    );
};

export const OffChainTokenList = (props: any) => {
    const dapp = useDapp();

    return (
        <List {...props} title={<OffChainTokenListTitle dapp={dapp}/>} actions={<OffChainTokenListActions dapp={dapp}/>} aside={<OffChainTokenListAside dapp={dapp}/>}>
            <Datagrid>
                <NumberField source="tokenID" label="TokenID"/>
                <TextField source="claimStatus" label="Claim Status"/>
                <TextField source="gameAssetIdentifier" label="Game Asset Identifier"/>
                <TextField source="gameAccountIdentifier" label="Game Account Identifier"/>
                <TextField source="contractID" label="Contract Name"/>

                <EditButton />
            </Datagrid>
        </List>
    );
}

