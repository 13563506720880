import { Breadcrumb, 
    BreadcrumbItem, 
    ResourceBreadcrumbItems, 
    DashboardBreadcrumbItem} from "@react-admin/ra-navigation";
import { useDapp } from "./dapp-context";

export const CustomBreadcrumb = () => {
    const dapp = useDapp();

    return (
        <Breadcrumb>
            <DashboardBreadcrumbItem label={dapp.dappID} to={undefined} />
            <ResourceBreadcrumbItems resources={["dapps", "dapp_offChainTokens", "sessions"]} />
            <BreadcrumbItem name="create_nft" label="Create NFT" />
            <BreadcrumbItem name="upload_tokens" label="Upload Tokens" />
            <BreadcrumbItem name="dapp_contracts" label="Contracts" to="/dapp_contracts">
                <BreadcrumbItem name="show" label={({record}) => {
                    // @ts-ignore
                    return `${record?.contractName}`
                }} />
                <BreadcrumbItem name="edit" label={({record}) => {
                    // @ts-ignore
                    return `${record?.contractName}`
                }} />
            </BreadcrumbItem>

            <BreadcrumbItem name="dapp_handlers" label="Handlers" to="/dapp_handlers">
                <BreadcrumbItem name="show" label={({record}) => {
                    // @ts-ignore
                    return `${record?.name}`
                }} />
            </BreadcrumbItem>

            <BreadcrumbItem name="dapp_tokens" label="Tokens" to="/dapp_tokens">
                <BreadcrumbItem name="edit" label={({record}) => {
                    // @ts-ignore
                    return `${record?.contractName} #${record?.tokenID}`
                }} />
            </BreadcrumbItem>

        </Breadcrumb>
    )
}