import {List, TopToolbar, Datagrid, TextInput, TextField, NumberField, EditButton, ExportButton, DateField} from "react-admin";

export const SessionList = (props: any) => (
    <List {...props} title="Dapp User Sessions">
        <Datagrid>
            <TextField source="id" label="Session ID" />
            <TextField source="session.user.scopelyId" label="Scopely ID" />
            <TextField source="session.user.username" label="Username" />
            <TextField source="session.dappID" label="Dapp ID" />
            <DateField source="expires" showTime={true} />
        </Datagrid>
    </List>
);