import {Config} from "./index";
import AwsCognitoAuthProvider from "../awsCognitoAuthProvider";

const config: Config = {
    AuthProvider: AwsCognitoAuthProvider,
    juniperApi: {
        url: 'https://api.dev.jnpr.io',
    }
};

export default config;