import React, {useState, useContext} from "react";
import {
    Edit, SimpleForm, TextInput, NumberInput, TopToolbar,
    useNotify, useRefresh
} from "react-admin";
import BoltIcon from '@mui/icons-material/Bolt';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import { JsonField } from "react-admin-json-view";
import {DappAdminApiService} from "../services/DappAdminApiService";

import { useDapp } from '../dapp-context';

interface IMintState {
    isMintDrawerOpen: boolean,
    quantityToMint: number
}

const TokenEditContext = React.createContext({
    state: {
        isMintDrawerOpen: false,
        quantityToMint: 0
    },
    setState: (state: IMintState) => {}
});

const TokenEditTitle = ({ record }: any) => {
    return <span>{record ? `Token Details for ${record.contractName} #${record.tokenID}` : ''}</span>;
};


const TokenEditActions = (props: any) => {
    const { state, setState } = useContext(TokenEditContext);
    
    const openMintDrawer = () => {
        setState({...state, isMintDrawerOpen: true});
    };
    
    return (
        <TopToolbar>
            <Button disabled={state.isMintDrawerOpen} color="primary" startIcon={<BoltIcon/>} onClick={openMintDrawer}>Mint Additional Tokens</Button>
        </TopToolbar>
    )
};

const AsideToolbar = (props: any) => {
    return (
        <>
            <Button onClick={props.handleMint}>Mint</Button>
            <Button onClick={props.handleCancelMint}>Cancel</Button>
        </>
    );
}

const Aside = ({record}: any) => {
    const dappApiService = new DappAdminApiService();
    
    const { state, setState } = useContext(TokenEditContext);
    const notify = useNotify();
    const refresh = useRefresh();
    const dapp = useDapp();

    const setQuantityToMint = (quantity: number) => {
        setState({...state, quantityToMint: quantity});
    }

    const handleCancelMint = () => {
        setState({...state, isMintDrawerOpen: false});
    }
    
    const handleMint = async () => {
        setState({...state, isMintDrawerOpen: false});

        notify("Minting additional tokens...");

        await dappApiService.mintNft(dapp.dappID, record.contractName, record.tokenID, state.quantityToMint);

        notify("Done!");
        refresh();
    }

    return (
        <div style={{width: 500, margin: '1em', display: state.isMintDrawerOpen ? "block" : "none"}}>
            <Typography variant="h6">Mint Additional Tokens</Typography>
            {record && (
                <SimpleForm toolbar={<AsideToolbar handleMint={handleMint} handleCancelMint={handleCancelMint} />}>
                    <NumberInput source="state.quantityToMint" label="Quantity To Mint" onChange={event => setQuantityToMint(event.target.value) } />
                </SimpleForm>
            )}
        </div>
    );
}

export const TokenEdit = (props: any) => {
    const [state, setState] = useState({
        isMintDrawerOpen: false,
        quantityToMint: 0
    });
    const value = {state, setState};

    return (
        <TokenEditContext.Provider value={value}>
            <Edit {...props} title={<TokenEditTitle/>} aside={<Aside />} actions={<TokenEditActions/>}>
                <SimpleForm>
                    <TextInput disabled source="id" />
                    <TextInput disabled source="dappID" label="Dapp Id" />
                    <TextInput disabled source="contractID" label="Contract Id" />
                    <TextInput disabled source="contractName" label="Contract Name" />
                    <TextInput disabled source="contractAddress" label="Contract Address" />
                    <TextInput disabled source="tokenID" label="Token Id" />
                    <NumberInput disabled source="blockAt" label="Block At" />
                    <JsonField
                        source="state"
                        jsonString={false} // Set to true if the value is a string, default: false
                        reactJsonOptions={{
                            // Props passed to react-json-view
                            name: null,
                            collapsed: false
                        }}
                    />
                </SimpleForm>
            </Edit>
        </TokenEditContext.Provider>
    )
};
