import {Config} from "./index";
import FakeAuthProvider from "../FakeAuthProvider";

const config: Config = {
    AuthProvider: FakeAuthProvider,
    juniperApi: {
        url: 'http://localhost:4000',
    }
};

export default config;