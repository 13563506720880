import {Create, SimpleForm, TextInput, NumberInput, TopToolbar, ListButton, required} from "react-admin";
import ChevronLeft from '@mui/icons-material/ChevronLeft';

const DappCreateTitle = () => {
    return <span>Add new dapp</span>;
};

const DappCreateActions = ({ basePath }: { basePath?:any }) => (
    <TopToolbar>
        <ListButton label="Return To List" icon={<ChevronLeft />} />
    </TopToolbar>
);

const validateDappId = [required()]
const validateDisplayName = [required()]
const validateNetworkId = [required()]
const validateChainId = [required()]

export const DappCreate = (props: any) => (
    <Create {...props} title={<DappCreateTitle />} actions={<DappCreateActions />}>
        <SimpleForm>
            <TextInput source="dappID" validate={validateDappId} />
            <TextInput source="displayName" validate={validateDisplayName} />
            <NumberInput source="networkID" validate={validateNetworkId} />
            <NumberInput source="chainID" validate={validateChainId} />
        </SimpleForm>
    </Create>
);