import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import config from '../config/index';

export class DappAdminApiService {
    instance: AxiosInstance;
    
    constructor() {
        this.instance = axios.create({
            baseURL: `${config.juniperApi.url}/admin`,
        });
    }
    
    async getDapps() {
        try {
            const response = await this.instance.get('/dapps');
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }
    
    async getDappContracts(dappId: string) {
        try {
            const response = await this.instance.get('/dapp_contracts',this.getConfig(dappId));
            return response.data;
        } catch (error) {
            console.error(error);
        }        
    }

    async createNft(dappId: string, contractName: string, quantity: number, metadata: any) {
        try {
            return await this.instance.post(`/create-nft/${quantity}?contract=${contractName}`, metadata, this.getConfig(dappId));
        } catch (error) {
            console.error(error);
        }
    }
    
    async mintNft(dappId: string, contractName: string, tokenId: string, quantity: number) {
        try {
            return await this.instance.post(`/mint-nft/${quantity}?contract=${contractName}&tokenID=${tokenId}`, null, this.getConfig(dappId));
        } catch (error) {
            console.error(error);
        }
    }

    async uploadTokens(dappId: string, file: any) {
        try {
            return await this.instance.post(`/upload-tokens`, file, this.getConfig(dappId));
        } catch (error) {
            console.error(error);
        }
    }

    async getTemplateFile(dappId: string, contractName: string) {
        try {
            return await this.instance.get(`/upload-tokens/template?contract=${contractName}`, this.getConfig(dappId));
        } catch (error) {
            console.error(error);
        }
    }
    
    private getConfig(dappId: string): AxiosRequestConfig {
        return {
            headers: {
                'Accept': 'application/json',
                'x-scopely-dapp-id': dappId,
                'x-scopely-dapp-key': process.env.REACT_APP_SCOPELY_DAPP_SERVICES_KEY ?? ""
            }
        }
    }

}