import {Fragment} from "react";
import {
    Labeled, ArrayInput,
    SimpleFormIterator, TextInput,
    RadioButtonGroupInput, FormDataConsumer, NumberInput, DateInput, required
} from "react-admin";
import {ColorInput} from "./ColorInput";
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import {ColorResult} from "react-color";

export const OpenSeaMetadataInput = (props: any) => {
    
    const unixTimestampToDateString = (unixTimestamp: number) => {
        if (isNaN(unixTimestamp)) return;
        const date = new Date(unixTimestamp * 1000);

        const pad = '00';
        const yy = date.getFullYear().toString();
        const mm = (date.getMonth() + 1).toString();
        const dd = date.getDate().toString();
        const dateString = `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
        console.log('to input - dateString: ', dateString);
        return dateString;
    }
    
    const dateStringToUnixTimestamp = (dateString: string) => {
        const date = new Date(dateString);
        
        const unixTime = date.getTime() / 1000;
        console.log('to record - unixtime: ', unixTime);
        return unixTime;
    }
    
    const colorParser = (value: ColorResult) => {
       return value.hex.substring(1);
    }
    
    const colorFormatter = (value: string) => {
        return value ? "#" + value : "";
    }
    
    return (
        <Labeled label={<Typography variant="h4">OpenSea Metadata</Typography>}>
            <Fragment>
                <ColorInput source="background_color" label="background_color" parse={colorParser} format={colorFormatter} helperText="Background color of the item on OpenSea. Must be a six-character hexadecimal." />
                <TextInput source="external_url" label="external_url" helperText="This is the URL that will appear below the asset's image on OpenSea and will allow users to leave OpenSea and view the item on your site." />
                <TextInput source="animation_url" label="animation_url" helperText="A URL to a multi-media attachment for the item. 
                                                                                    The file extensions GLTF, GLB, WEBM, MP4, M4V, OGV, and OGG are supported, 
                                                                                    along with the audio-only extensions MP3, WAV, and OGA.  
                                                                                    Animation_url also supports HTML pages, allowing you to build rich experiences and 
                                                                                    interactive NFTs using JavaScript canvas, WebGL, and more. 
                                                                                    Scripts and relative paths within the HTML page are now supported. 
                                                                                    However, access to browser extensions is not supported." />
                <TextInput source="youtube_url" label="youtube_url" helperText="A URL to a YouTube video." />

                <Alert severity="info">
                    <AlertTitle>Attrributes</AlertTitle>
                    <p>
                        <a href="https://docs.opensea.io/docs/metadata-standards#attributes" target="_blank" rel="noreferrer">Click here </a> for more information about how attributes are displayed on OpenSea.
                    </p>
                </Alert>
                <ArrayInput source="attributes" helperText="show up underneath each of your assets">
                    <SimpleFormIterator>
                       <RadioButtonGroupInput label="display_type" source="display_type" helperText="Indicates how attribute will be displayed" choices={[
                           { id: '', name: 'none' },
                           { id: 'string', name: 'string' },
                           { id: 'number', name: 'number' },
                           { id: 'boost_percentage', name: 'boost_percentage' },
                           { id: 'boost_number', name: 'boost_number' },
                           { id: 'date', name: 'date' },
                       ]} />
                       <TextInput source="trait_type" label="trait_type" helperText="Name of the trait" />
                       <FormDataConsumer>
                           {({ formData, scopedFormData, getSource,...rest}) => {
                               
                               if (scopedFormData && scopedFormData.display_type && scopedFormData.display_type === 'string') {
                                   return (
                                       <TextInput source={getSource ? getSource("value") : ""} label="value" helperText="Value of the trait" validate={required()} />
                                   )
                               }

                               if (scopedFormData && scopedFormData.display_type && (scopedFormData.display_type === 'number' || scopedFormData.display_type === 'boost_percentage' || scopedFormData.display_type === 'boost_number')) {
                                   return (
                                   <>
                                       <NumberInput source={getSource ? getSource("value") : ""} label="value" validate={required()} style={{ display: 'block'}} helperText="Value of the trait" />
                                       <NumberInput source={getSource ? getSource("max_value") : ""} label="max_value" style={{ display: 'block'}} helperText="Sets a ceiling for a numerical trait's possible values. It defaults to the maximum that OpenSea has seen so far on the assets on your contract. If you set a max_value, make sure not to pass in a higher value."/>
                                   </>
                                   )
                               }

                               if (scopedFormData && scopedFormData.display_type && scopedFormData.display_type === 'date') {
                                   return (
                                       <DateInput source={getSource ? getSource("value") : ""} label="value" parse={dateStringToUnixTimestamp} format={unixTimestampToDateString} />
                                   )
                               }

                           }}
                       </FormDataConsumer>
                   </SimpleFormIterator> 
                </ArrayInput>
            </Fragment>
        </Labeled>
    )
}