import { Show, SimpleForm, TextInput } from "react-admin";
import { useDapp } from '../dapp-context';

const HandlerShowTitle = ({ record }: any) => {
    return <span>{record ? `Details For Handler ${record.name}` : ''}</span>;
};

export const HandlerShow = (props: any) => {
    return (
        <Show {...props} title={<HandlerShowTitle/>}>
            <SimpleForm warnWhenUnsavedChanges={false}>
                <TextInput disabled source="id" />
                <TextInput disabled source="fullName" />
                <TextInput disabled source="name" />
                <TextInput disabled source="path" />
                <TextInput disabled source="source" multiline={true} />
            </SimpleForm>
        </Show>
    )
};