import React, {Fragment} from "react";
import {MetadataItem} from "./MetadataItem";

export const MetadataProperties = (props: any) => {
    return (
        <Fragment>
            {props.templates ? props.templates.map((template: any) => <MetadataItem template={template} source={props.source + "[" + template.name + "]"} />) : "No custom properties defined"}
        </Fragment>

    )    
    
}