import { List, TopToolbar, Datagrid, TextField,  ShowButton, ExportButton } from "react-admin";
import { useDapp } from '../dapp-context';

const HandlerListTitle = (props: any) => {
    return <span>{`Handlers for ${props.dapp ? props.dapp.dappID : 'DApp'}`}</span>;
};

const HandlerListActions = (props: any) => (
    <TopToolbar>
        <ExportButton
            disabled={props.total === 0}
            resource={props.resource}
        />
    </TopToolbar>
);

export const HandlerList = (props: any) => {
    const dapp = useDapp();
    
    return (
        <List {...props} title={<HandlerListTitle dapp={dapp}/>} actions={<HandlerListActions/>} filter={{dappId: dapp.dappID}}>
            <Datagrid>
                <TextField source="fullName" label="Full Name"/>
                <TextField source="name" label="Name"/>
                <TextField source="path" label="Path"/>
                <ShowButton />
            </Datagrid>
        </List>
    );
};