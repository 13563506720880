import * as React from "react";
import { Title } from 'react-admin';
import {Card, CardContent, CardHeader} from "@mui/material";

const Dashboard = () => (
    <Card>
        <Title title="Dashboard" />
        <CardHeader title="Welcome to the Blockchain Admin Tool" />
        <CardContent>
            Exciting times ahead...
        </CardContent>
    </Card>
);
export default Dashboard;