import { fetchUtils} from 'react-admin';

import simpleRestProvider from 'ra-data-simple-rest';
import config from './config/index';

const dappScopedHTTPClient = (url: string, options: fetchUtils.Options = {}) => {
    const dappId = localStorage.getItem('dappID');

    if (dappId) {
        options.headers = fetchUtils.createHeadersFromOptions(options);

        if (!options.headers.get('Accept')) {
            options.headers.set('Accept', 'application/json');
        }

        options.headers.set('x-scopely-dapp-id', dappId);
        options.headers.set('x-scopely-dapp-key', process.env.REACT_APP_SCOPELY_DAPP_SERVICES_KEY ?? "");
    }
    
    return fetchUtils.fetchJson(url, options);
};


const DappDataProvider = (basePath: string) => {
    return simpleRestProvider(`${config.juniperApi.url}${basePath}`, dappScopedHTTPClient);
}

export default DappDataProvider;