import genericDataProvider from './GenericDataProvider';
import dappDataProvider from './DappDataProvider';

const defaultDataProvider    = genericDataProvider('/admin');
const dappAdminDataProvider  = dappDataProvider('/admin');
const dappClientDataProvider = dappDataProvider('/client');

const dataProviders = [
    { dataProvider: defaultDataProvider,    resources: ['dapps', 'sessions'] },
    { dataProvider: dappAdminDataProvider,  resources: ['dapp_contracts', 'dapp_handlers', 'dapp_tokens', 'dapp_offChainTokens'] },
    { dataProvider: dappClientDataProvider, resources: [] },
];

const dataProviderMethodMap = {
    GET_LIST:           'getList',
    GET_ONE:            'getOne',
    GET_MANY:           'getMany',
    GET_MANY_REFERENCE: 'getManyReference',
    CREATE:             'create',
    UPDATE:             'update',
    UPDATE_MANY:        'updateMany',
    DELETE:             'delete',
    DELETE_MANY:        'deleteMany'
};

const SuperDataProvider = (type, resource, params) => {
    const dataProviderMapping = dataProviders.find(dp => dp.resources.includes(resource)) || dataProviders[0];
    return dataProviderMapping.dataProvider[dataProviderMethodMap[type]](resource, params);
};

export default SuperDataProvider;