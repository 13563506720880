import { Layout } from "@react-admin/ra-enterprise";
import { AppLocationContext } from "@react-admin/ra-navigation";
import CustomAppBar from './CustomAppBar';
import {CustomMenu} from "./CustomMenu";
import { useDapp } from './dapp-context';
import {Backdrop, Box, Typography} from "@mui/material";
import {CoreLayoutProps} from "react-admin";
import {PropsWithChildren} from "react";
import {CustomBreadcrumb} from "./CustomBreadcrumb";

const CustomLayout = ({children, ...rest}: PropsWithChildren<CoreLayoutProps>) => {
    const dapp = useDapp();
    if (!dapp) {
        return (
            <Backdrop open={true}>
                <Box>
                    <Typography variant="h1">
                        No dapps exist
                    </Typography>
                    <Typography variant="subtitle2">
                        Use createDapp gulp task to create a new dapp.  If you have already created a dapp, use the publsh gulp task to add it to the database.
                    </Typography>
                </Box>
            </Backdrop>
        )
    } else {
        return(
            <AppLocationContext>
                <Layout
                    {...rest}
                    appBar={CustomAppBar}
                    menu={CustomMenu}
                    breadcrumb={<CustomBreadcrumb />}
                >
                    {children}
                </Layout>
            </AppLocationContext>
        );
    }
}

export default CustomLayout;