import * as React from 'react';
import { AppBar } from "@react-admin/ra-enterprise";
import {DappSelector} from "./DappSelector";

const CustomAppBar = (props: any) => {
    return (
        <AppBar {...props} languages={[]}>
            <DappSelector />
        </AppBar>
    );
};

export default CustomAppBar;