import polyglotI18nProvider from 'ra-i18n-polyglot';

import englishMessages from 'ra-language-english';
import domainMessages from './i18n';

const messages: {en: any, [index: string]: any} = {
    en: { ...englishMessages, ...domainMessages.en }
};

const i18nProvider = polyglotI18nProvider((locale) => {
    return messages[locale];
});

export default i18nProvider;