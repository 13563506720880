import {AuthProvider} from "react-admin";
import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
    Auth: {
        region: 'us-west-2',
        identityPoolRegion: 'us-west-2',
        userPoolId: 'us-west-2_DiFKDvUS2',
        userPoolWebClientId: 'mkbp4vn8h8nlss283gs05mrhl',
    }
});

const AwsCognitoAuthProvider: AuthProvider = {

    // called when the user attempts to log in
    login: ({ username, password }) => {
        return Auth.signIn(username, password);
    },

    // called when the user clicks on the logout button
    logout: () => {
        return Auth.signOut({global: true});
    },

    // called when the API returns an error
    checkError: ({ status }) => {
        return Promise.resolve();
    },

    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return Auth.currentSession().then(session => {
            if (!session) {
                return Promise.reject('you need to sign in');
            }
            return Promise.resolve();
        })
    },
        
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};

export default AwsCognitoAuthProvider;