import {Admin} from "@react-admin/ra-enterprise";
import {Resource, CustomRoutes} from 'react-admin';
import Dashboard from "./Dashboard";
import {DappProvider} from "./dapp-context";
import {DappList} from "./dapps/DappList";
import {DappEdit} from "./dapps/DappEdit";
import {DappCreate} from "./dapps/DappCreate";
import {ContractList} from "./contracts/ContractList";
import {ContractEdit} from "./contracts/ContractEdit";
import {HandlerList} from "./handlers/HandlerList";
import {HandlerShow} from "./handlers/HandlerShow";
import {TokenList} from "./tokens/TokenList";
import {TokenEdit} from "./tokens/TokenEdit";

import CustomLayout from "./CustomLayout";
import superDataProvider from "./SuperDataProvider";
import i18nProvider from "./i18n/i18nProvider";
import { defaultTheme } from "react-admin";
import {OffChainTokenList} from "./offChainTokens/OffChainTokenList";
import {OffChainTokenEdit} from "./offChainTokens/OffChainTokenEdit";
import {SessionList} from "./sessions/SessionList";
import config from './config/index';
import {ContractShow} from "./contracts/ContractShow";
import {CreateNFT} from "./CreateNFT";
import {UploadTokens} from "./UploadTokens";
import {Route} from "react-router-dom";
import AppsIcon from '@mui/icons-material/Apps';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DirectionsIcon from '@mui/icons-material/Directions';
import TokenIcon from '@mui/icons-material/Token';
import PersonIcon from '@mui/icons-material/Person';


const customTheme = {
    ...defaultTheme,
    sidebar: {
        width: 105,
        closedWidth: 50
    }
};

const App = () => (
    <DappProvider>    
        <Admin 
            dashboard={Dashboard} 
            authProvider={config.AuthProvider} 
            dataProvider={superDataProvider}
            i18nProvider={i18nProvider}
            layout={CustomLayout} 
            theme={customTheme} 
            title="Blockchain Admin" 
            disableTelemetry 
        >
            <CustomRoutes noLayout={false}>
                <Route path="/create_nft" element={<CreateNFT />} />
                <Route path="/upload_tokens" element={<UploadTokens />} />
            </CustomRoutes>
            <Resource name="dapps" list={DappList} edit={DappEdit} create={DappCreate} icon={AppsIcon} options={{label: 'DApps'}} />
            <Resource name="dapp_contracts" list={ContractList} edit={ContractEdit} show={ContractShow} icon={AssignmentIcon} options={{label: 'Contracts'}}  />
            <Resource name="dapp_handlers" list={HandlerList} show={HandlerShow} icon={DirectionsIcon} options={{label: 'Handlers'}} />
            <Resource name="dapp_tokens" list={TokenList} edit={TokenEdit} icon={TokenIcon} options={{label: 'Tokens'}} />
            <Resource name="dapp_offChainTokens" list={OffChainTokenList} edit={OffChainTokenEdit} icon={TokenIcon} options={{label: 'Off Chain Tokens'}} />
            <Resource name="sessions" list={SessionList} icon={PersonIcon} options={{label: 'User Sessions'}} />
            
        </Admin>
    </DappProvider>
);

export default App;
