import {List, Datagrid, TextField, NumberField, EditButton, TopToolbar, ExportButton} from "react-admin";

const DappListActions = (props: any) => (
    <TopToolbar>
        <ExportButton
            disabled={props.total === 0}
            resource={props.resource}
        />
    </TopToolbar>
);

export const DappList = (props: any) => {
    return(
        <List {...props} actions={<DappListActions/>}>
            <Datagrid>
                <TextField source="dappID" label="Dapp Id" />
                <TextField source="displayName" label="Display Name" />
                <NumberField source="networkID" label="Network Id" />
                <NumberField source="chainID" label="Chain Id" />
                <EditButton />
            </Datagrid>
        </List>
    );
};

