import {FieldTitle, useInput, useResourceContext} from "react-admin";
import {ChromePicker, ColorResult} from 'react-color';
import React, {useState} from "react";
import {TextField} from "@mui/material";

require('./css/colorInput.css');

export const ColorInput = (props: any) => {
    const resource = useResourceContext(props);

    const { onChange, onBlur, ...rest } = props;

    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted },
        isRequired
    } = useInput(props);
    
    const [show, setShow] = useState(false);
    
    const handleOpen = () => {
        setShow(true);
    }
    
    const handleClose = () => {
        setShow(false);
    }
    
    const handleChange = (hex: ColorResult) => {
        field.onChange(hex);
    };

    return (
        <div>
            <TextField
                {...field}
                margin="normal"
                onFocus={handleOpen}
                label={
                    <FieldTitle
                        label={props.label}
                        source={props.source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                }
                error={!!(isTouched && error)}
                helperText={(isTouched && error) || props.helperText}
                className={props.className}
            />
            {
                show?
                    <div className="ColorInput-popup">
                        <div
                            className="ColorInput-cover"
                            onClick={handleClose}
                        />
                        <ChromePicker
                            color={field.value}
                            onChange={handleChange}
                        />
                    </div>
                    : null
            }
        </div>
    )
}