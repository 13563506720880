import {Edit, SimpleForm, TextInput, NumberInput, useRecordContext} from "react-admin";
import { JsonField } from "react-admin-json-view";

const ContractEditTitle = () => {
    const record = useRecordContext();
    return <span>{record ? `Contract Details for ${record.contractName}` : ''}</span>;
};

export const ContractEdit = () => {
    return (
        <Edit title={<ContractEditTitle/>}>
            <SimpleForm warnWhenUnsavedChanges={true}>
                <TextInput disabled source="contractID" />
                <TextInput disabled source="contractName" />
                <TextInput disabled source="contractAddress" />
                <NumberInput disabled source="blockAt" />
                <JsonField
                    source="state"
                    jsonString={false} // Set to true if the value is a string, default: false
                    reactJsonOptions={{
                        // Props passed to react-json-view
                        name: null,
                        collapsed: false,
                        theme: "summerfruit"
                    }}
                />
            </SimpleForm>
        </Edit>
    );
};