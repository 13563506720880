import configDefault from './default';
import configDevelopment from './development';
import * as _ from "lodash";
import { AuthProvider } from 'react-admin';

export interface Config {
    AuthProvider: AuthProvider;
    juniperApi: {
        url: string;
    }
}

let config = {};
_.merge(config, configDefault);

if (process.env.REACT_APP_JUNIPER_ENV == 'development') {
    _.merge(config, configDevelopment);
}

export default config as Config;