import * as React from 'react'
import {DappAdminApiService} from "./services/DappAdminApiService";
import {DappModel} from "./models/dappModel";

type Action = {type: 'dapps_loaded', dapps: any} | {type: 'dapp_selected', dappId: string}
type Dispatch = (action: Action) => void
type State = {dappId: string, dapps: any, dapp: any}
type DappProviderProps = {children: React.ReactNode}

const DappContext = React.createContext<{state: State, dispatch: Dispatch} | undefined>(undefined);

function dappReducer(state: State, action: Action) {
    switch (action.type) {
        case 'dapps_loaded': {
            return {
                ...state,
                dapps: action.dapps
            };
        }
        case 'dapp_selected': {
            const dapp = state.dapps? state.dapps.find((d: { dappID: string; }) => d.dappID === action.dappId) : null;

            localStorage.setItem('dappID', action.dappId);
            return {
                ...state,
                dapp: dapp,
                dappId: action.dappId
            };
        }
        default: {
            throw new Error(`Unhandled action type`);
        }
    }
}

function DappProvider({children}: DappProviderProps) {

    const [state, dispatch] = React.useReducer(dappReducer, {
        dappId: "",
        dapp: null,
        dapps: null
    });

    React.useEffect(() => {
        const dappApiService = new DappAdminApiService();

        dappApiService.getDapps().then((dappsList) => {
            let dappId = localStorage.getItem('dappID');

            //If no dapp is found in localStorage then use first dapp in the list of dapps
            if (!dappId || dappId === "null") {
                if (dappsList && dappsList.length > 0) {
                    dappId = dappsList[0].dappID;
                    localStorage.setItem('dappID', dappId as string);
                }
            }
            else {
                //If the dapp from localStorage is no longer in the list of dapps, then use first dapp in the list
                if (dappsList && dappsList.length > 0 && !dappsList.find((d: DappModel) => d.dappID === dappId)) {
                    dappId = dappsList[0].dappID;
                    localStorage.setItem('dappID', dappId as string);
                }                
            }
            
            dispatch({type: 'dapps_loaded', dapps: dappsList});
            dispatch({type: 'dapp_selected', dappId: dappId as string});
        });
    }, []);

    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = {state, dispatch};
    return <DappContext.Provider value={value}>{children}</DappContext.Provider>
}


function useDappContext() {
    const context = React.useContext(DappContext);
    if (context === undefined) {
        throw new Error('useDapp must be used within a DappProvider')
    }

    return context;
}

function useDapp() {
    const context = useDappContext();
    return context.state.dapp;
}

export {DappProvider, useDappContext, useDapp }
