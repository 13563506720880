import Card from "@mui/material/Card";
import {
    Title,
    SimpleForm,
    SaveContextProvider,
    TextInput,
    required,
    SaveButton,
    SelectInput, 
    NumberInput,
    ImageInput,
    ImageField,
    Labeled,
    RaRecord
} from "react-admin";
import { useDapp } from "./dapp-context";
import React, {useEffect, useMemo, useState, useCallback, ChangeEvent} from "react";
import BoltIcon from '@mui/icons-material/Bolt';
import {DappAdminApiService} from "./services/DappAdminApiService";
import { useDefineAppLocation } from "@react-admin/ra-navigation";
import {convertFileToBase64} from "./utils/imageUtils";
import {MetadataProperties} from "./MetadataProperties";
import {OpenSeaMetadataInput} from "./OpenSeaMetadataInput";
import {CardContent} from "@mui/material";

const CreateNFTToolbar = (props: any) => {
    return (
        // Non-Input components under SimpleForm produce errors,
        // removed the toolbar therefor
        <SaveButton
            {...props}
            label="Create NFT"
            disabled={props.pristine}
            icon={<BoltIcon />}
        />
    );
}

interface TemplateList {
    [propName: string]: any;
}

export const CreateNFT = () => {
    useDefineAppLocation('create_nft');

    const dappApiService = new DappAdminApiService();
    const dapp = useDapp();
    const [saving, setSaving] = useState(false);

    const templateList: TemplateList = {};
    
    const [contracts, setContracts] = useState([]);
    const [templates, setTemplates] = useState(templateList);
    const [activeTemplate, setActiveTemplate] = useState([]);

    useEffect(() => {
        async function fetchContracts() {
            const fetchedContracts = await dappApiService.getDappContracts(dapp.dappID);
            const erc1155Contracts = fetchedContracts.filter(function(c: { state: { ERC1155: any; }; }) { return c.state.ERC1155});
            setContracts(erc1155Contracts);

            if (dapp) {
                setTemplates(dapp.metadataPropertyTemplates);
            }
        }
        fetchContracts();

    }, [setContracts, setTemplates, dapp]);
    
    const handleSave = useCallback( async (values) => {
        setSaving(true);
        console.log(values);

        const imageData = await  convertFileToBase64(values.image.rawFile);
        values.image = imageData;

        const contract = values.contract;
        const quantity = values.quantity;

        //remove values that we don't want included in the metadata
        delete values.contract;
        delete values.quantity;
        
        await dappApiService.createNft(dapp.dappID, contract, quantity, values);
        setSaving(false);
        
    },[dapp.dappID]);
    
    const handleContractSelected = (evt: RaRecord | ChangeEvent<HTMLInputElement>) => {
        if (templates) {
            const template = templates[evt.target.value];
            setActiveTemplate(template);
        }
    };
    
    // needed by the saveContext that I don't understand
    const onFailure = () => {
        
    };
    
    //don't really understand this???
    const saveContext = useMemo(() => ({
        setOnFailure: onFailure,
        save: handleSave,
        saving
    }), [saving, handleSave]);


    const record = {
        contract: "",
        quantity: 1,
        name: "",
        description: "",
        image: "",
        properties: {},
        attributes: []
    };

    return (
        <>
        <Card>
            <Title title="Create NFT"/>
            <CardContent>
                <SaveContextProvider value={saveContext} >
                    <SimpleForm record={record} 
                                onSubmit={handleSave} 
                                toolbar={<CreateNFTToolbar />}
                    >
                        <SelectInput source="contract" label="Contract" choices={contracts} optionText="contractName" optionValue = "contractName" onChange={handleContractSelected} />
                        <NumberInput source="quantity" label="Quantity" min={1} />
                        <TextInput source="name" label="Name" validate={required()}/>
                        <TextInput source="description" label="Description" validate={required()}/>
                        <ImageInput source="image" label="Image" accept="image/*" validate={required()}>
                            <ImageField source="src" title="title" />
                        </ImageInput>
                        <Labeled label="Properties">
                            <MetadataProperties source="properties" templates={activeTemplate} />
                        </Labeled>
                        <OpenSeaMetadataInput/>
                    </SimpleForm>
                </SaveContextProvider>
            </CardContent>
        </Card>
        </>
    );
}