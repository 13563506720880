import { useDappContext } from "./dapp-context";
import Select, {ActionMeta, StylesConfig} from "react-select";
import * as React from "react";
import {OnChangeValue} from "react-select/dist/declarations/src/types";

function DappSelector() {
    const {state, dispatch} = useDappContext();

    const isLoading = state.dapps == null;

    let options = [];
    let selectedOption = null;
    if (!isLoading) {
        for (let dapp of state.dapps) {
            const option = { value: dapp.dappID, label: dapp.displayName };
            options.push(option);
            if (dapp.dappID === state.dappId) {
                selectedOption = option;
            }
        }
    }

    const customStyles: StylesConfig = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'bblack' : 'black',
            padding: 20,
        }),
        control: (provided, state) => ({
            ...provided,
        }),
        placeholder: (provided, state) => ({
            ...provided,
        }),
    }

    const placeholder = isLoading ? 'Loading...' : 'Select Dapp...';

    function onChange(evt: any) {
        if (evt) {
            dispatch({type: 'dapp_selected', dappId: evt.value});
            window.location.reload();
        }
    }

    return (
        <Select value={selectedOption} options={options} styles={customStyles} placeholder={placeholder} onChange={onChange} />
    )
}

export {DappSelector}
